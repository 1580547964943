import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import FuelSystemLinesForm from "./FuelSystemLinesForm";
import { Dialog } from "primereact/dialog";
import { useErrorHandler } from "../../services/errorhandler";
import { RolesService } from "../../services/roles.service.ts";
import { useDispatch } from "react-redux";
import { Toast } from "primereact/toast";
import { FuelMappingService } from "../../services/fuelSystem.service.ts";
import { slice } from "../../store/slices/auth.js";
import { useCommonService } from "../../services/useCommonService.ts";
import FuelSystemReadingsImport from "./FuelSystemReadingsImport.js";
import { Tooltip } from 'primereact/tooltip';
import { format } from "date-fns";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import debounce from 'lodash.debounce';
import OnShowCalendar from "../FuelSystemMapping/CustomCalender.js";
import FSLRImportError from "./FSLRImportError.js"
import NoteDialog from "./NoteData.js";
const rowsPerPageOptions = [10, 25, 50, 100, 200]
const FuelSystemLinesReadings = (props) => {
  const { sites, filerData: filters, updateGlobalFilters: setGlobalFilters, getSiteData, stateOptions,getStateDatails,gridHeightMode,tableId,profile } = props
  const dispatch = useDispatch();
  const commonService = useCommonService();

  const [selectedProducts, setSelectedProducts] = useState({});
  const [formData, setFormData] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const errorHandler = useErrorHandler();
  const [fuelMapping, setFuelMapping] = useState({ fuelList: [], totalRecords: 0 });
  const [maxWidth, setMaxWidth] = useState(window.innerWidth - 250);
  const [importDialog, setImportDialog] = useState(false);
  const [calculateVarianceDialog, setCalculateVarianceDialog] = useState(false);
  const [isShowSites, setIsShowSites] = useState(false);
  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 50,
    page: 0,
  });
  const formatDatePickerDate = (date) => {
    if (!date) return null;
    const finaldate = format(date, 'yyyy-MM-dd');
    return finaldate;
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setMaxWidth(window.innerWidth - 250);
      } else {
        setMaxWidth(window.innerWidth);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (getSiteData) {
      getFuelMapping();
    }
  }, [lazyState, getSiteData]);
  const [finaldata, setFinalData] = useState({})
  const [childData, setChildData] = useState({ fuelList: [] });
  const [expandedParentData, setExpandedParentData] = useState({});
  const [newlyAddedRecords, setNewlyAddedRecord] = useState([]);
  const [importErrorDialog, setImportErrorDialog] = useState(false);
  const [importErrorData, setImportErrorData] = useState({ error: [], fileName: '' });
  const [csvData, setCSVData] =useState([]);
  const dtCSV = useRef(null);

  const getFuelMapping = () => {
    const getIds = (data, key) => {
      return data.map((e) => e[key])
    }
    const getsiteIds = getIds(filters?.sites, "_id")
    const payload = {
      site_id: getsiteIds,
      start_date: (filters.dates) ? formatDatePickerDate(filters?.dates?.[0]) : formatDatePickerDate(new Date(new Date().setDate(new Date().getDate() - 30))),
      end_date: (filters.dates) ? formatDatePickerDate(filters?.dates?.[1]) : formatDatePickerDate(new Date()),
      state: filters?.statesValue,
      show_sites_without_readings: isShowSites
    };
    commonService.startLoading();
    RolesService.getFuelSystemLinesReadingParentList(lazyState.page + 1, lazyState.rows, payload)
      .pipe(errorHandler("Get Fuel System Lines Reading"))
      .subscribe((response) => {
        if (response?.data) {
          const data = (response?.data?.results);
          setFuelMapping({ fuelList: data, totalRecords: response?.data?.count });
        }
        commonService.stopLoading();
      });
  };

  const getChildGridData = (id) => {
    const payload = {
      site_id: [id],
      start_date: (filters.dates) ? formatDatePickerDate(filters?.dates?.[0]) : formatDatePickerDate(new Date(new Date().setDate(new Date().getDate() - 30))),
      end_date: (filters.dates) ? formatDatePickerDate(filters?.dates?.[1]) : formatDatePickerDate(new Date()),
      show_sites_without_readings: isShowSites
    };
    commonService.startLoading();
    RolesService.getFuelSystemLinesReadingChildList(payload)
      .pipe(errorHandler("Get Fuel System Lines Reading"))
      .subscribe((response) => {
        if (response?.data) {
          const data = (response?.data);
          setChildData({ fuelList: data });

        }
        commonService.stopLoading();
      });
  };

  const getNameByCode = (code) => {
    const site = sites?.find((site) => site._id === code);
    return site ? site.facility_name : code;
  };

  const createNewReading = (data) => {
    const finalValue = { "readings": data }
    commonService.startLoading();
    FuelMappingService.createNew(finalValue)
      .pipe(errorHandler("Creating new reading"))
      .subscribe((response) => {
        if (response?.data) {
          dispatch(
            slice.actions.updateToast({
              severity: "success",
              message: response.data?.message || "new reading created Successfully",
            })
          );
          let newIds = response.data.map((item) => { return item._id });
          setNewlyAddedRecord((prevItems) => [...prevItems, ...newIds]);
          setFormData(null);
          getChildGridData(expandedParentData?._id);
        }
      });
  };
  const callDeleteForArray = (data) => {
    const payload = {
      inventory_variance_id: data,
    };
    commonService.startLoading();
    FuelMappingService.delete(payload)
      .pipe(errorHandler("Delete Reading"))
      .subscribe((response) => {
        if (response?.data) {
          dispatch(
            slice.actions.updateToast({
              severity: "success",
              message: response.data?.message || "Deleted Successfully",
            })
          );
          getChildGridData(expandedParentData?._id);;
          commonService.stopLoading();
        }
      });
  };
  const handleReadingDeleteClick = (rowData) => {
    callDeleteForArray([rowData?._id]);
  };

  const handleBulkDeleteBySite = (selectedReadingsOfSite) => {
    const ids = selectedReadingsOfSite.map((item) => item._id);
    ids?.length && callDeleteForArray(ids);
  };
  const onReadingEditComplete = (data) => {
    if(data?.data._id){
      const payload =
      {
        _id: data?.data?._id,
        beginning_inventory_volume: data?.data?.beginning_inventory_volume,
        actual_ending_inventory_volume:
          data?.data?.actual_ending_inventory_volume,
        sales: data?.data?.sales,
        deliveries: data?.data?.deliveries,
      }
      FuelMappingService.fuelsystemupdate(data?.data?._id, payload)
        .pipe(errorHandler("Update Reading"))
        .subscribe((response) => {
          if (response?.data) {
            dispatch(
              slice.actions.updateToast({
                severity: "success",
                message: response.data?.message || "Updated Successfully",
              })
            );
            getChildGridData(expandedParentData?._id);
          }
        });
    }else{
      let value = data?.data;
      value.date = formatDate(value?.date);
      const siteName = expandedParentData?.facility_name
      value.store_number = siteName?.split('-')[1]
      value.actual_ending_inventory_volume = (value.actual_ending_inventory_volume == null || value.actual_ending_inventory_volume == undefined) 
        ? 0 : value.actual_ending_inventory_volume;
      value.beginning_inventory_volume = (value.beginning_inventory_volume == null || value.beginning_inventory_volume == undefined)
        ? 0 : value.beginning_inventory_volume;
      value.calculated_ending_inventory = (value.calculated_ending_inventory == null || value.calculated_ending_inventory == undefined)
       ? 0 : value.calculated_ending_inventory;
       value.deliveries = (value.deliveries == null || value.deliveries == undefined) ? 0: value.deliveries;
       value.sales = ( value.sales == null ||  value.sales == undefined) ? 0: value.sales;
      createNewReading([value]);
    }
  };
  const [editvalues, setEditValues] = useState({})
  const onRowEditInit = (e) => {
    const { data } = e;
    if (data) {
      setEditValues({
        site_id: data?._id,
        beginning_inventory_volume: data.beginning_inventory_volume,
        actual_ending_inventory_volume: data.actual_ending_inventory_volume,
        sales: data.sales,
        deliveries: data.deliveries,
        calculated_ending_inventory: data.calculated_ending_inventory,
        ending_inventory_difference: data.ending_inventory_difference,
      });
    }
  };

  const onReadingEditCancel = (data) => {
    if (editvalues) {
      data.data.beginning_inventory_volume = editvalues.beginning_inventory_volume;
      data.data.actual_ending_inventory_volume = editvalues.actual_ending_inventory_volume;
      data.data.sales = editvalues.sales;
      data.data.deliveries = editvalues.deliveries;
      data.data.calculated_ending_inventory = editvalues.calculated_ending_inventory;
      data.data.ending_inventory_difference = editvalues.ending_inventory_difference;
      setChildData((prev) => {
        const updateFuelList = [...prev.fuelList];
        updateFuelList[data.index] = { ...data.data };
        return { ...prev, fuelList: updateFuelList };
      });
    }
  }
  const handleValueChange = debounce((e, field, rowIndex, data, parentid) => {
    const updatedChildData = { ...data[rowIndex] };
    updatedChildData[field] = e.value;
    updatedChildData.calculated_ending_inventory =
      updatedChildData.beginning_inventory_volume + updatedChildData.deliveries - updatedChildData.sales;


    updatedChildData.ending_inventory_difference =
      updatedChildData.actual_ending_inventory_volume - updatedChildData.calculated_ending_inventory;
    setFinalData(updatedChildData);
    setChildData((prev) => {
      const updatedFuelList = [...prev.fuelList];
      updatedFuelList[rowIndex] = updatedChildData;
      return { ...prev, fuelList: updatedFuelList };
    });
  }, 200);

  const [noteForm, setNoteForm] = useState(null);
  const NoteCustomButton = (rowData) => {
    return <Button
      icon="pi pi-file"
      className="p-button-rounded p-button-text p-button-secondary actions-edit-btn"
      onClick={() => setNoteForm(rowData?._id)}
    />
  }
  const actionColumn = (rowData, columnProps) => {
    return columnProps.rowEditor.editing ? (
      <>
        <Button
          icon="pi pi-check"
          className="p-button-rounded p-button-text p-button-success"
          onClick={columnProps?.rowEditor?.onSaveClick}
        />
        <Button
          icon="pi pi-times"
          className="p-button-rounded p-button-text p-button-danger"
          onClick={columnProps?.rowEditor?.onCancelClick}
        />
      </>
    ) : (
      <>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text p-button-secondary actions-edit-btn"
          onClick={columnProps.rowEditor.onInitClick}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text p-button-danger"
          onClick={() => handleReadingDeleteClick(rowData)}
          disabled={(rowData?._id == null) ? true : false}
        />
      </>
    );
  };
  const exportCSVData = (event) => {
    event.preventDefault();
    const getIds = (data, key) => {
      return data.map((e) => e[key])
    }
    const getsiteIds = getIds(filters?.sites, "_id")
    const payload = {
      client_id: profile?.client,//updatename?.client_id,
      site_id: getsiteIds,
      start_date: (filters.dates) ? formatDatePickerDate(filters?.dates?.[0]) : formatDatePickerDate(new Date(new Date().setDate(new Date().getDate() - 30))),
      end_date: (filters.dates) ? formatDatePickerDate(filters?.dates?.[1]) : formatDatePickerDate(new Date()),
      state: filters?.statesValue,
      show_sites_without_readings: isShowSites
    };
    commonService.startLoading();
    RolesService.fuelSystemLineReadingsExport(payload)  // getFuelStystemLinesList
      .pipe(errorHandler("Get Fuel System Lines Reading Eport"))
      .subscribe((response) => {
        if (response?.data) {
          setCSVData(formatDataForExport(response?.data));
          dtCSV.current?.exportCSV();
          setCSVData([]);
         
        }
        commonService.stopLoading();
      });

  }
  const formatDataForExport = (data) => {
    return data.map(item => ({
      ...item,
      date: item.date ? formatDate(item.date) : '',
      beginning_inventory_date: item.beginning_inventory_date ?  formatDate(item.beginning_inventory_date) : '',
    }));
  };
  const headerElement = (
    <div className="fw-semibold mx-4 mt-3">{`Create Fuel System Lines`}</div>
  );
  const formatDate = (dateString) => {
    const date = new Date(dateString).toISOString();
    const datestr = date.split('T')[0]
    return datestr;
  };

  const formatNumber = (value) => {
    let newVal = value;
    if (typeof value !== "number") {
      newVal = Number(value);
    }
    return newVal ? newVal?.toFixed(2) : "0.00";
  };

  const onFilter = () => {
    setChildData({ fuelList: [] });
    setExpandedRow(null);
    setExpandedParentData({});
    setSelectedProducts({})
    setNewlyAddedRecord([])
    const getIds = (data, key) => {
      return data.map((e) => e[key])
    }
    const getsiteIds = getIds(filters?.sites, "_id")
    const payload = {
      site_id: getsiteIds,
      start_date: (filters.dates) ? formatDatePickerDate(filters?.dates?.[0]) : formatDatePickerDate(new Date(new Date().setDate(new Date().getDate() - 30))),
      end_date: (filters.dates) ? formatDatePickerDate(filters?.dates?.[1]) : formatDatePickerDate(new Date()),
      state: filters?.statesValue,
      show_sites_without_readings: isShowSites
    };
    commonService.startLoading();
    RolesService.getFuelSystemLinesReadingParentList(lazyState.page + 1, lazyState.rows, payload)
      .pipe(errorHandler("Get Fuel System Lines Reading"))
      .subscribe((response) => {
        if (response?.data) {
          const data = (response?.data?.results);
          setFuelMapping({ fuelList: data, totalRecords: response?.data?.count });
        }
        commonService.stopLoading();
      });
  };

  const handlePageChange = (e) => {
    setLazyState(e);
  };
  const isNewRecord = (id) => {
    return newlyAddedRecords?.includes(id);
  }
  const rowClassName = (data) => ((isNewRecord(data?._id)) ? 'newlyAddedRecord' : '');
  const hiddenNoteColumn =  childData?.fuelList?.some(row => row._id !== null);

  const containerRef = useRef(null);
  const dataTableWrapperRef = useRef(null);
  const startY = useRef(0);
  const startHeight = useRef(0);

  const onMouseDown = (e) => {
    startY.current = e.clientY;
    startHeight.current = containerRef.current.offsetHeight;
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const onMouseMove = (e) => {
    const newHeight = startHeight.current + (e.clientY - startY.current);
    containerRef.current.style.height = `${newHeight}px`;

    if (dataTableWrapperRef.current) {
      dataTableWrapperRef.current.style.height = `${newHeight - 40}px`; // Adjust to fit content
    }
  };

  const onMouseUp = () => {
    document.removeEventListener("mousemove", onMouseMove);
    document.removeEventListener("mouseup", onMouseUp);
  };
  useEffect(() => {
    dataTableWrapperRef.current = document.querySelector("#inventoryTruckGrid .p-datatable-wrapper");
    if (gridHeightMode === "Component" && containerRef.current) {
      const container = containerRef.current;
      container.style.height = "calc(100vh - 10px)";
      if (dataTableWrapperRef.current) {
        const containerHeight = container.offsetHeight;
        dataTableWrapperRef.current.style.height = `${containerHeight - 40}px`;
      }
    }
  }, [gridHeightMode]);

  const rowExpansionTemplate = (data) => {
    return (
      <div>
        <div className="d-flex flex-wrap justify-content-end gap-2">
          <Button className="new-reading" label="New Reading" text onClick={() => setFormData({ client_id: profile?.client, site_id: data?._id })} />
          <Button
            label="Delete"
            text
            className="delete-button"
            disabled={
              (isShowSites)? isShowSites:(!selectedProducts[data._id]?.length ||
              selectedProducts[data._id]?.length < 2)
            }
            onClick={() => handleBulkDeleteBySite(selectedProducts[data._id])}
          />
        </div>
        <Dialog appendTo="self" align={"left"} header={"Notes"} visible={!!noteForm} modal onHide={() => setNoteForm(null)}>
          <NoteDialog  sites={sites} editData={noteForm} onCancel={() => setNoteForm(null)} name="fuelline-Reading" />
        </Dialog>
        <div ref={containerRef} className={tableId ? "h-30 rounded overflow-auto" : "border rounded overflow-auto h-10 grid-resizable-inventory-container"} style={{minHeight:"200px"}}>

        <DataTable
          selectionMode={"checkbox"}
          value={childData.fuelList || []}
          // id="fuelLinesChildGrid"
          id={tableId ?? "inventoryTruckGrid fuelLinesChildGrid"}
          dataKey={childData.fuelList._id}
          selection={selectedProducts[data._id]}
          showSelectAll={false}
          editMode="row"
          onRowEditComplete={onReadingEditComplete}
          onRowEditCancel={onReadingEditCancel}
          onRowEditInit={onRowEditInit}
          scrollable
          scrollHeight={"430px"}
          onSelectionChange={(e) => setSelectedProducts({ ...selectedProducts, [data._id]: e.value })}
          className="child-fuel-grid rounded border flex-grow-1"
          // draggable={true}
          rowClassName={rowClassName}
        >
          <Column selectionMode="multiple" header={"Selected"} headerStyle={{ width: "2rem" }} />
          <Column field="tank_number" header="Tank Number" headerStyle={{ width: "7rem" }} />
          <Column field="product" header="Product" />
          <Column field="date" header="Date" body={(rowData) => formatDate(rowData?.date)} />

          <Column field="beginning_inventory_volume" header="Beginning Inventory Volume" headerStyle={{ width: "4rem" }}
            editor={(props) => (
              <InputNumber
                value={props.rowData?.beginning_inventory_volume || 0}
                onChange={(e) => handleValueChange(e, 'beginning_inventory_volume', props.rowIndex, childData?.fuelList, data._id)}
              />
            )}
          />

          <Column field="actual_ending_inventory_volume" header="Actual Ending Inventory Volume" headerStyle={{ width: "4rem" }}
            editor={(props) => (
              <InputNumber
                value={props.rowData?.actual_ending_inventory_volume || 0}
                onChange={(e) => handleValueChange(e, 'actual_ending_inventory_volume', props.rowIndex, childData?.fuelList, data._id)}
              />
            )}
          />

          <Column field="sales" header="Sales"
            editor={(props) => (
              <InputNumber
                value={props.rowData.sales || 0}
                onChange={(e) => handleValueChange(e, 'sales', props.rowIndex, childData?.fuelList, data._id)}
              />
            )}
          />
          <Column field="deliveries" header="Deliveries" body={(rowData) => formatNumber(rowData?.deliveries)}
            editor={(props) => (
              <InputNumber
                value={props.rowData.deliveries || 0}
                onChange={(e) => handleValueChange(e, 'deliveries', props.rowIndex, childData.fuelList, data._id)}
              />
            )} />
          <Column field="calculated_ending_inventory" header="Book Value" className="BookValue" body={(rowData) => formatNumber(rowData?.calculated_ending_inventory)} />
          <Column field="ending_inventory_difference" header="Variance" body={(rowData) => formatNumber(rowData?.ending_inventory_difference)} />
          {hiddenNoteColumn && <Column header={"Notes"} align={'center'} body={NoteCustomButton} />}
          <Column rowEditor header={"Actions"} align={"center"} body={actionColumn} />
        </DataTable>
        {gridHeightMode === "Tab" && <div className="resizable-handle" onMouseDown={onMouseDown} />}
        </div>

      </div>
    );
  };
  const onRowToggle = (e) => {
    setChildData({ fuelList: [] });
    setNewlyAddedRecord([])
    if (e.originalEvent?.originalEvent?.currentTarget?.ariaExpanded == "false") {
      setExpandedParentData(e?.data);
      getChildGridData(e.data._id);
    } else {
      setExpandedParentData({});
    }
    if (expandedRow && expandedRow._id === e.data._id) {
      setExpandedRow(null);
    } else {
      setExpandedRow(e.data);
    }
    setSelectedProducts({});
  };
  const allowExpansion = (rowData) => {
    return true;
  };
  const siteNameBody = (rowData) => {
    const siteName = (rowData?.facility_name);
    return <div>{siteName}</div>;
  };
  const handleImport = (values) => {
    commonService.startLoading();
    const formData = new FormData();
    formData.append("fuel_reading_file", values.file);
    formData.append("upload_from_ui", true);
    formData.append("client_id", profile?.client);
    FuelMappingService.importFuelSystemLineReading(formData).subscribe({
      next: (response) => {
        if (response?.data) {
          setImportDialog(false);
          dispatch(
            slice.actions.updateToast({
              severity: "success",
              message: "Fuel system Readings imported.",
            })
          );
          commonService.stopLoading();
        }
      },
      error: (error) => {
        if (error?.response?.data) {
          setImportErrorData({ error: error?.response?.data?.errors, fileName: error?.response?.data?.file_name });
          setImportErrorDialog(true);
        }
        commonService.stopLoading();
      }
    })
  };

  const handleRowsPerPageChange = (e) => {
    setLazyState({
      ...lazyState,
      rows: e.value,
      page: 0,
      first: 0
    });
  };
  const onShow = () => {
    let selectAllCheckbox = document.querySelector(
      ".p-multiselect-header > .p-multiselect-select-all > .p-checkbox"
    );
    selectAllCheckbox.after(" Select All");
  };


  return (
    <React.Fragment>
      <Dialog
        appendTo="self"
        visible={!!formData}
        modal
        header={headerElement}
        onHide={() => setFormData(null)}
      >
        <FuelSystemLinesForm
          editData={formData}
          site={expandedParentData?._id}
          siteName={expandedParentData?.facility_name}
          onSave={(data) => createNewReading(data)}
          onCancel={() => setFormData(null)}
        />
      </Dialog>
      <Dialog
        appendTo="self"
        visible={importErrorDialog}
        modal
        header={
          <div className="fw-semibold mx-4 mt-3">{`Import Error`}</div>
        }
        onHide={() => {
          setImportErrorDialog(false);
          setImportDialog(false);
        }}
      >
        <FSLRImportError
          errorData={importErrorData?.error}
          fileName={importErrorData?.fileName}
        />
      </Dialog>
      <div className="d-flex flex-column p-1 fsl-main-container">
        <div className="col-12 d-flex flex-row flex-wrap flex-grow-1 p-0 pt-2 mb-0">
          <div className="col-12 col-md-2 d-flex flex-column gap-1  px-1 flex-grow-1 m-2 mx-0 mt-0">
            <label htmlFor="site">Site Name</label>
            <MultiSelect
              value={filters?.sites}
              options={sites}
              loading={!sites.length}
              optionLabel="facility_name"
              onChange={(e) => {
                {setGlobalFilters('sites', e.value);}
              }}
              placeholder="Select Sites"
              className="w-80"
              filter
              maxSelectedLabels={3}
              display='chip'
             // selectAllLabel={"Select All"}
            />
          </div>
          <div className="col-12 col-md-2 d-flex flex-column gap-1  px-1 flex-grow-1 m-2 mt-0">
            <Tooltip target=".custom-target-icon" />
            <label htmlFor="date">Date Range <i className="custom-target-icon pi pi-info-circle p-text-secondary"
              data-pr-tooltip="Default date range is T-30 days"
              data-pr-position="right"
              style={{ fontSize: '0.8rem', cursor: 'pointer' }}>
            </i></label>
            <Calendar
              onShow={OnShowCalendar}
              onChange={({ target: { name, value } }) => { setGlobalFilters(name, value); OnShowCalendar(); }}
              value={filters?.dates}
              onHide={() => (!filters.dates?.[0] || !filters.dates?.[1]) && setGlobalFilters("dates", null)}
              selectionMode="range"
              readOnlyInput
              hideOnRangeSelection
              placeholder="Select Date"
              id="dates"
              name="dates"
              showButtonBar
            />
          </div>
          <div className="col-12 col-md-2 d-flex flex-column gap-1 px-1 flex-grow-1 m-2 mt-0">
            <label htmlFor="state">State</label>
            <MultiSelect
              value={filters?.statesValue}
              options={stateOptions}
              loading={!stateOptions?.length}
              optionValue="name"
              optionLabel="name"
              onChange={(e) => setGlobalFilters('statesValue', e.value)}
              placeholder="Select States"
             // onShow={onShow}
              filter
              maxSelectedLabels={3}
              display='chip'
              //selectAllLabel="Select All"
            />
          </div>
          <div className="col-12 col-md-3 d-flex flex-row align-items-center pt-2 px-1 flex-grow-1 m-2 mt-0">
            <Checkbox
              checked={isShowSites}
              onChange={e => setIsShowSites(e.checked)}
              className="mt-3 mx-2"
              inputId="showSitesWithoutReading"
            >
            </Checkbox>
            <label htmlFor="showSitesWithoutReading" className="text mt-3">Show Sites Without Reading</label>
          </div>

          <div className="ccol-12 col-md-3 d-flex flex-row justify-content-end align-items-end  gap-1 p-0 px-1 flex-grow-1 m-2 mx-0 mt-0">
            <Button label="Apply" className="filter-sign" onClick={onFilter} text>
              <i className="pi pi-filter filter-action-icon"></i>
            </Button>
            <Button label="Import" className="filter-sign" text onClick={() => setImportDialog(true)}>
              <i className="pi pi-download import-action-icon"></i>
            </Button>
            <Button label="Export" className="filter-sign" text onClick={(e) => { exportCSVData(e) }}>
              <i className="pi pi-upload export-action-icon"></i>
            </Button>

            <div className="d-flex">
              <span className="mt-1 mx-2">Show: </span>
              <Dropdown
                value={lazyState.rows}
                options={rowsPerPageOptions}
                onChange={handleRowsPerPageChange}
                className="p-dropdown"
              />
            </div>
          </div>
        </div>

        <div>
          <Dialog
            appendTo="self"
            visible={importDialog}
            modal
            header={
              <div className="fw-semibold mx-4 mt-3">
                {`Import Fuel System Readings`}
              </div>
            }
            onHide={() => setImportDialog(false)}
          >
            <FuelSystemReadingsImport
              sites={sites}
              onCancel={() => setImportDialog(false)}
              onSubmit={handleImport}
            />
          </Dialog>
       
        </div>

        <Toast ref={toast} />

        <div className="w-100 rounded customGrid">
          <DataTable
            id="fuelLinesReadingTabGrid"
            ref={dt}
            value={fuelMapping?.fuelList}
            totalRecords={fuelMapping.totalRecords}
            dataKey="_id"
            showGridlines={false}
            scrollable
            size="large"
            className="fuel-system-grid rounded border flex-grow-1"
            paginator
            lazy={true}
            rows={lazyState.rows}
            first={lazyState.first}
            pageLinkSize={10}
            rowsPerPageOptions={rowsPerPageOptions}
            paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
            currentPageReportTemplate="{first} - {last} of {totalRecords} Records"
            reorderableColumns
            resizableColumns
            rowExpansionTemplate={rowExpansionTemplate}
            expandedRows={[expandedRow]}
            onRowExpand={onRowToggle}
            onRowCollapse={onRowToggle}
            emptyMessage="No records found."
            onPage={handlePageChange}
          >
            <Column expander={allowExpansion} style={{ width: "3rem" }} />
            <Column body={siteNameBody} field="facility_name" headerStyle={{ textAlign: "center" }} bodyStyle={{ textAlign: "center" }} header="Site Name" />
          </DataTable>
        </div>
        <div className="d-none">
          <DataTable 
          ref={dtCSV} 
          value={csvData} 
          dataKey="_id" 
          scrollable 
          virtualScrollerOptions={{ itemSize: 50 }}
          exportFilename='fuel_system_reading'
          >
              <Column field='store_number' header='Store Number'></Column>
              <Column field='tank_number' header='Tank Number'></Column>
              <Column field='date' header='Date'></Column>
              <Column field='product' header='Product'></Column>
              <Column field='beginning_inventory_date' header='Beginning Inventory Date'></Column>
              <Column field='water_level' header='Water Level'></Column>
              <Column field='beginning_inventory_volume' header='Beginning Inventory Volume'></Column>
              <Column field='beginning_inventory_level' header='Beginning Inventory Level'></Column>
              <Column field='deliveries' header='Deliveries'></Column>
              <Column field='sales' header='Sales'></Column>
              <Column field='calculated_ending_inventory' header='Calculated Ending Inventory'></Column>
              <Column field='actual_ending_inventory_volume' header='Actual Ending Inventory Volume'></Column>
              <Column field='ending_inventory_difference' header='Ending Inventory Difference'></Column>
            </DataTable>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FuelSystemLinesReadings;
