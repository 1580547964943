import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import FuelSystemLinesDetailTab from "./FuelSystemLinesDetailTab";
import FuelSystemLinesReadings from "./FuelSystemLinesReadings";
import { useErrorHandler } from "../../services/errorhandler";
import { SiteDDLService } from "../../services/sitesDdl.service.ts";
import { SitesService } from "../../services/site.service.ts";
import { useLocation, useNavigate } from "react-router-dom";
import { UserProfileService } from '../../services/userProfille.service.ts'

const FuelSystemLines = () => {
  const errorHandler = useErrorHandler();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [sites, setSites] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({
    dates: [
      new Date(new Date().setDate(new Date().getDate() - 30)),
      new Date(),
    ],
    sites: [],
    statesValue: [],
  });
  const [activeIndex, setActiveIndex] = useState(0); // Set initial active index to 1 (second tab)
  const [isSiteddlData, setISSiteddlData] = useState(false);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    if (state?.number ?? 0) {
      setActiveIndex(state?.number)
    }
  }, [])
  const getUserProfile = () => {
    UserProfileService.getUserProfile().pipe(errorHandler("Get UserProfile")).subscribe((response) => {
      if (response?.data) {
        setProfile(response?.data[0]);
      }
    })
  }
  useEffect(() => {
    getUserProfile();
  }, []);
  const getStateDatails = (siteId) => {
    setStateOptions([]);
    updateGlobalFilters("statesValue", []);
    let site_id = {}

    if (siteId.length > 0) {
      site_id = {
        site_id: siteId?.map((site) => site?._id)
      }
    } else {
      site_id = {
        site_id: []
      }
    }
    SitesService.getStates(site_id).pipe(errorHandler("Get State")).subscribe((res) => {
      if (res?.data) {
        setStateOptions(res?.data);
      }
    });
  };

  const getSiteDetails = () => {
    const payload = {
      is_admin: false
    }
    SiteDDLService.getSitesDDL(payload)
      .pipe(errorHandler("Get Sites"))
      .subscribe((response) => {
        if (response?.data) {
          setSites(response?.data);
          setISSiteddlData(true);
        }
      });
  };

  useEffect(() => {
    const siteSubscription = SiteDDLService.observeSites$.subscribe((res) => {
      if (res.length > 0) {
        setSelectedFilter((prev) => {
          const updatedFilters = { ...prev };
          updatedFilters["sites"] = res;
          return updatedFilters;
        });
      }
    });
    const dateSubscription = SiteDDLService.observeDateRange$.subscribe((res) => {
      if (res && res.length > 0) {
        setSelectedFilter((prev) => {
          const updatedFilters = { ...prev };
          updatedFilters["dates"] = res;
          return updatedFilters;
        });
      }
    });
    getSiteDetails();
    // getStateDatails();
    return () => {
      siteSubscription.unsubscribe();
      dateSubscription.unsubscribe();
    }
  }, []);
  useEffect(() => {
    getStateDatails(selectedFilter?.sites);
  }, [selectedFilter?.sites])

  const updateGlobalFilters = (name, value) => {
    setSelectedFilter((prev) => {
      const updatedFilters = { ...prev };
      updatedFilters[name] = value;
      return updatedFilters;
    });
    if (name == "sites")
      SiteDDLService.observeSites$.next(value);
    else if (name == "dates")
      SiteDDLService.observeDateRange$.next(value);
  };

  return (
    <div className="card h-100 w-80 d-flex rounded p-2 flex-grow-1 section_bg">
      <TabView
        className="FuelSystemLinesTabView flex-grow-1"
        activeIndex={activeIndex} // Set the active tab index dynamically
        onTabChange={(e) => {
          setActiveIndex(e.index)
          if (state?.number) {
            navigate("/fuel-system-lines", { replace: true });
          }
        }} // Update activeIndex on tab change
      >
        <TabPanel header="Fuel System Readings">
          <FuelSystemLinesReadings
            sites={sites}
            gridHeightMode="Tab"
            tableId={"fuelLinesChildGrid"}
            filerData={selectedFilter}
            updateGlobalFilters={updateGlobalFilters}
            stateOptions={stateOptions}
            getSiteData={isSiteddlData}
            getStateDatails={getStateDatails}
            profile={profile}
          />
        </TabPanel>
        <TabPanel header="Fuel System Line Details">
          <FuelSystemLinesDetailTab
            gridHeightMode="Tab"
            tableId={"fuelLinesChildGrid"}
            sites={sites}
            filerData={selectedFilter}
            updateGlobalFilters={updateGlobalFilters}
            stateOptions={stateOptions}
            profile={profile}
          />
        </TabPanel>
      </TabView>
    </div>
  );
};

export default FuelSystemLines;
