import { Observable } from "rxjs";
import { ApiService } from "./api.service.ts";
import { USERPROFILE_URL,ANALYTICS_URL } from "./apiUrls.ts";

export const UserProfileService = {
  getUserProfile: (): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(USERPROFILE_URL.GET_USERSPROFILE, "GET", "").subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  sitesList: (page: number, pageSize: number): Observable<any> => {
    return new Observable<any>((observer) => {
      let url = `${ANALYTICS_URL.GET_SITES}?limit=${page}&offset=${pageSize}`;

      ApiService.call(url, "GET","").subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  updatePassword: (body : any): Observable<any> => {
    return new Observable<any>((observer) => {

      ApiService.call(USERPROFILE_URL.UPDATE_PASSWORD, "POST",body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
}