import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import { Column } from "primereact/column";
import { statusList } from "./data";
import { format } from 'date-fns'
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { useErrorHandler } from "../../services/errorhandler";
import { RolesService } from "../../services/roles.service.ts";
import { useDispatch } from "react-redux";
import { FuelMappingService } from "../../services/fuelSystem.service.ts";
import { slice } from "../../store/slices/auth.js";
import "./FuelSystemLines.css";
import { useCommonService } from "../../services/useCommonService.ts";
import { Tooltip } from 'primereact/tooltip';
import debounce from 'lodash.debounce';
import OnShowCalendar from "../FuelSystemMapping/CustomCalender.js";
import { useLocation } from "react-router-dom";
import { ReactComponent as SortIcon } from '../../assets/Sorting.svg'
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import NoteDialog from "./NoteData.js";
import { exportCSV } from "./ExportData.js";
const rowsPerPageOptions = [25, 50, 100, 200]

const FuelSystemLinesDetailTab = (props) => {
  const { sites, filerData: filters, updateGlobalFilters: setGlobalFilters, stateOptions, gridHeightMode, tableId,profile } = props
  const dispatch = useDispatch();
  const [values, setValues] = useState([])
  const commonService = useCommonService();
  const [expandedRow, setExpandedRow] = useState(null);
  const dt = useRef(null);
  const errorHandler = useErrorHandler();
  const [selectedProducts, setSelectedProducts] = useState({});
  const [fuelMapping, setFuelMapping] = useState({ fuelList: [], totalRecords: 0 });
  const [statusValue, setStatuesValue] = useState("All");
  const [formaValues, setFormValues] = useState({ maxValue: null, minValue: null })
  const [dailyVarianceStatus, setDailyVarianceStatus] = useState("All");
  const [maxWidth, setMaxWidth] = useState(window.innerWidth - 250);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [sortType, setSortType] = useState("asc")
  const [editvalues, setEditValues] = useState({})
  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 50,
    page: 0,
  });
  const greatervalue = ">="
  const LessThanvalue = "<="
  const { state } = useLocation();
  const [chartData, setChartData] = useState(state)
  const [childData, setChildData] = useState({ fuelList: [] });
  const [expandedParentData, setExpandedParentData] = useState({});
  const [overUnderFilter, setOverUnderFilter] = useState({ maxValue: null, minValue: null })
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setMaxWidth(window.innerWidth - 250);
      } else {
        setMaxWidth(window.innerWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    let filterSites = []
    if (chartData?.siteId) {
      filterSites.push(chartData?.siteIobj)
    }
    if (chartData?.siteId) {
      setGlobalFilters('sites', [chartData?.siteIobj])
      setGlobalFilters("dates", chartData?.only_date)
    } else {
      filterSites = filters.sites
    }
    getFuelMapping(filterSites);


  }, [lazyState, sortType, sortField]); //eslint-disable-line
  const formatDatePickerDate = (dateString) => {
    if (!dateString) return null;
    const date = format(dateString, 'yyyy-MM-dd');
    return date;
  };
  const getFuelMapping = (sites) => {
    const getIds = (data, key) => {
      return data.map((e) => e[key])
    }
    const getsiteIds = getIds(sites, "_id")
    const payload = {
      client_id: profile?.client,
      site_id: getsiteIds,
      // product: ["#2 DIESEL"],
      start_date: (filters.dates) ? formatDatePickerDate(filters?.dates?.[0]) : formatDatePickerDate(new Date(new Date().setDate(new Date().getDate() - 30))),
      end_date: (filters.dates) ? formatDatePickerDate(filters?.dates?.[1]) : formatDatePickerDate(new Date()),
      status: statusValue,
      state: filters?.statesValue,
      daily_variance_status: dailyVarianceStatus,
      daily_variance_min: parseInt(formaValues?.minValue),
      daily_variance_max: parseInt(formaValues?.maxValue),
      variance_min: parseInt(overUnderFilter?.minValue),
      variance_max: parseInt(overUnderFilter?.maxValue),
    };
    if (formaValues?.minValue != null && formaValues.maxValue != null && formaValues?.minValue != "" && formaValues.maxValue != "") {
      if (payload.daily_variance_min > payload.daily_variance_max) {
        dispatch(
          slice.actions.updateToast({
            severity: "error",
            message: "Daily Variance Status minimum value is not to be greater than the maximum value",
          })
        );
        return;
      }
    }
    if (overUnderFilter?.minValue != null && overUnderFilter.maxValue != null && overUnderFilter?.minValue != "" && overUnderFilter.maxValue != "") {
      if (payload.variance_min > payload.variance_max) {
        dispatch(
          slice.actions.updateToast({
            severity: "error",
            message: "Leak Check Status minimum value is not to be greater than the maximum value",
          })
        );
        return;
      }
    }
    commonService.startLoading();
    RolesService.getFuelSystemLinesDetailsParentList(lazyState.page + 1, lazyState.rows, payload, sortType, sortField)
      .pipe(errorHandler("Get Fuel System Lines Details Parent List"))
      .subscribe((response) => {
        if (response?.data) {
          const data = (response?.data);
          setFuelMapping({ fuelList: data.results, totalRecords: data?.count });
          // setFilteredData(data.results);
        }
        commonService.stopLoading();
      });
  };

  const childDataCall = (e) => {
    const payload = {
      client_id: e.client_id,
      site_id: e.site_id,
      period_start: (filters.dates) ? formatDatePickerDate(filters?.dates?.[0]).replaceAll('/', '-') : formatDatePickerDate(new Date(new Date().setDate(new Date().getDate() - 30))).replaceAll('/', '-'),
      period_end: (filters.dates) ? formatDatePickerDate(filters?.dates?.[1]).replaceAll('/', '-') : formatDatePickerDate(new Date()).replaceAll('/', '-'),
      product: e.Fule_System_Name,
      daily_variance_status: dailyVarianceStatus,
      daily_variance_min: parseInt(formaValues?.minValue),
      daily_variance_max: parseInt(formaValues?.maxValue),
    }
    if (formaValues?.minValue != null && formaValues.maxValue != null && formaValues?.minValue != "" && formaValues.maxValue != "") {
      if (payload.daily_variance_min > payload.daily_variance_max) {
        dispatch(
          slice.actions.updateToast({
            severity: "error",
            message: "Daily Variance Status minimum value is not to be greater than the maximum value ",
          })
        );
        return;
      }
    }
    commonService.startLoading();
    RolesService.getFuelSystemLinesDetailsChildList(payload)
      .pipe(errorHandler("Get Fuel System Lines Details Child Data"))
      .subscribe((response) => {
        if (response?.data) {
          setChildData({ fuelList: response?.data ?? [] });
          setValues(response?.data)
        }
        commonService.stopLoading()
      });
  };


  useEffect(() => {
    const index = childData?.fuelList?.findIndex((data) => data?.product === chartData?.product && data?.only_date === chartData?.Datelabel.replace(/\//g, '-'));
    if (childData?.fuelList && childData?.fuelList.length > 0) {
      const row = document.querySelectorAll("#fuelLinesChildGrid tr td:last-child")[index];

      if (row) {
        const editButton = row.firstChild;
        if (editButton) {
          editButton.click();
        }
      }
    }
  }, [values]); //eslint-disable-line

  const callDeleteForArray = (rowData) => {
    FuelMappingService.DeleteFuelSytemLineDetails(rowData?.id)
      .pipe(errorHandler("Delete Reading"))
      .subscribe((response) => {
        if (response?.data) {
          dispatch(
            slice.actions.updateToast({
              severity: "success",
              message: response.data?.message || "Deleted Successfully",
            })
          );
          setChartData(null)
          childDataCall(expandedParentData);
        }
      });
  };
  const handleReadingDeleteClick = (rowData) => {
    callDeleteForArray(rowData);
  };
  const onReadingEditComplete = (data) => {
    const payload =
    {
      // _id: data?.data?.id,
      beginning_inventory_volume: data?.data?.beginning_inventory_volume,
      actual_ending_inventory_volume:
        data?.data?.actual_ending_inventory_volume,
      sales: data?.data?.sales,
      deliveries: data?.data?.deliveries ?? 0,
    }


    FuelMappingService.updateFuelSystemLineDetails(data?.data?.id, payload)
      .pipe(errorHandler("Update Reading"))
      .subscribe((response) => {
        if (response?.data) {
          dispatch(
            slice.actions.updateToast({
              severity: "success",
              message: response.data?.message || "Updated Successfully",
            })
          );
          childDataCall(expandedParentData);
          setChartData(null)
          commonService.startLoading();
          getFuelMapping(filters?.sites)
        }
      });
  };

  const onRowEditInit = (e) => {
    const { data } = e;
    if (data) {
      setEditValues({
        site_id: data?.id,
        beginning_inventory_volume: data.beginning_inventory_volume,
        actual_ending_inventory_volume: data.actual_ending_inventory_volume,
        sales: data.sales,
        deliveries: data.deliveries,
        calculated_ending_inventory: data.calculated_ending_inventory,
        ending_inventory_difference: data.ending_inventory_difference,
        daily_variance: data.daily_variance,
        daily_variance_status: data.daily_variance_status
      });
    }
  };
  const [finaldata, setFinalData] = useState({})

  const onReadingEditCancel = (data) => {
    if (editvalues) {
      data.data.beginning_inventory_volume = editvalues.beginning_inventory_volume;
      data.data.actual_ending_inventory_volume = editvalues.actual_ending_inventory_volume;
      data.data.sales = editvalues.sales;
      data.data.deliveries = editvalues.deliveries;
      data.data.calculated_ending_inventory = editvalues.calculated_ending_inventory;
      data.data.ending_inventory_difference = editvalues.ending_inventory_difference;
      data.data.daily_variance = editvalues.daily_variance;
      data.data.daily_variance_status = editvalues.daily_variance_status;
      setChildData((prev) => {
        const updateFuelList = [...prev.fuelList];
        updateFuelList[data.index] = { ...data.data };
        return { ...prev, fuelList: updateFuelList };
      });
    }
  }


  const handleValueChange = debounce((e, field, rowIndex, data) => {
    const updatedChildData = { ...data[rowIndex] };
    updatedChildData[field] = e.value;
    updatedChildData.book_value = updatedChildData.beginning_inventory_volume + updatedChildData.deliveries - updatedChildData.sales;
    updatedChildData.variance = updatedChildData.actual_ending_inventory_volume - updatedChildData.book_value;
    updatedChildData.daily_variance = (updatedChildData.variance / updatedChildData.sales) * 100;
    updatedChildData.daily_variance_status = (Math.abs(updatedChildData.daily_variance) > 1.2) ? "Fail" : "Pass";
    setFinalData(updatedChildData);
    setChildData((prev) => {
      const updatedFuelList = [...prev.fuelList];
      updatedFuelList[rowIndex] = updatedChildData;
      return { ...prev, fuelList: updatedFuelList };
    });
  }, 300);

  const actionColumn = (rowData, columnProps) => {
    return columnProps.rowEditor.editing ? (
      <>
        <Button
          icon="pi pi-check"
          className="p-button-rounded p-button-text p-button-success"
          onClick={columnProps.rowEditor.onSaveClick}
        />
        <Button
          icon="pi pi-times"
          className="p-button-rounded p-button-text p-button-danger"
          onClick={columnProps.rowEditor.onCancelClick}
        />
      </>
    ) : (
      <>
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-secondary" onClick={columnProps.rowEditor.onInitClick} />
        <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger" onClick={() => handleReadingDeleteClick(rowData)} />
      </>
    );
  };

  const [formData, setFormData] = useState(null);
  const NoteCustomButton = (rowData) => {
    return <Button
      icon="pi pi-file"
      className="p-button-rounded p-button-text p-button-secondary actions-edit-btn"
      onClick={() => setFormData(rowData?.id)}
    />
  }

  const exportSitesData = (event) => {
    event.preventDefault();

    const getIds = (data, key) => {
      return data.map((e) => e[key])
    }
    const getsiteIds = getIds(filters?.sites, "_id")
    const payload = {
      client_id: profile?.client,
      site_id: getsiteIds,
      start_date: (filters.dates) ? formatDatePickerDate(filters.dates[0]).replaceAll('/', '-') : formatDatePickerDate(new Date(new Date().setDate(new Date().getDate() - 30))).replaceAll('/', '-'),
      end_date: (filters.dates) ? formatDatePickerDate(filters.dates[1]).replaceAll('/', '-') : formatDatePickerDate(new Date()).replaceAll('/', '-'),
      daily_variance_status: dailyVarianceStatus,
      daily_variance_min: parseInt(formaValues?.minValue),
      daily_variance_max: parseInt(formaValues?.maxValue),
      variance_min: parseInt(overUnderFilter?.minValue),
      variance_max: parseInt(overUnderFilter?.maxValue),
    };
    if (formaValues?.minValue != null && formaValues.maxValue != null && formaValues?.minValue != "" && formaValues.maxValue != "") {
      if (payload.daily_variance_min > payload.daily_variance_max) {
        dispatch(
          slice.actions.updateToast({
            severity: "error",
            message: "Daily Variance Status minimum value is not to be greater than the maximum value ",
          })
        );
        return;
      }
    }
    if (overUnderFilter?.minValue != null && overUnderFilter.maxValue != null && overUnderFilter?.minValue != "" && overUnderFilter.maxValue != "") {
      if (payload.variance_min > payload.variance_max) {
        dispatch(
          slice.actions.updateToast({
            severity: "error",
            message: "Leak Check Status minimum value is not to be greater than the maximum value ",
          })
        );
        return;
      }
    }
    commonService.startLoading();
    FuelMappingService.exportFuelLinesData(payload)
      .pipe(errorHandler("Get Generate Export"))
      .subscribe((response) => {
        if (response?.data) {
          exportCSV(response.data);
        }
        commonService.stopLoading();
      });
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString).toISOString();
    const datestr = date.split('T')[0]
    return datestr;
  };
  const formatNumber = (value) => {
    let newVal = value;
    if (typeof value !== "number") {
      newVal = Number(value);
    }
    return newVal ? newVal?.toFixed(2) : "0.00";
  };

  const onFilter = () => {
    setChartData(null)
    setChildData({ fuelList: [] });
    setExpandedRow(null);
    setExpandedParentData({})
    const getIds = (data, key) => {
      return data.map((e) => e[key])
    }
    const getsiteIds = getIds(filters?.sites, "_id")
    const payload = {
      client_id: profile?.client,
      site_id: getsiteIds,
      start_date: (filters.dates) ? formatDatePickerDate(filters.dates[0]).replaceAll('/', '-') : formatDatePickerDate(new Date(new Date().setDate(new Date().getDate() - 30))).replaceAll('/', '-'),
      end_date: (filters.dates) ? formatDatePickerDate(filters.dates[1]).replaceAll('/', '-') : formatDatePickerDate(new Date()).replaceAll('/', '-'),
      status: statusValue,
      state: filters?.statesValue,
      daily_variance_status: dailyVarianceStatus,
      daily_variance_min: parseInt(formaValues?.minValue),
      daily_variance_max: parseInt(formaValues?.maxValue),
      variance_min: parseInt(overUnderFilter?.minValue),
      variance_max: parseInt(overUnderFilter?.maxValue),
    };
    if (formaValues?.minValue != null && formaValues.maxValue != null && formaValues?.minValue != "" && formaValues.maxValue != "") {
      if (payload.daily_variance_min > payload.daily_variance_max) {
        dispatch(
          slice.actions.updateToast({
            severity: "error",
            message: "Daily Variance Status minimum value is not to be greater than the maximum value ",
          })
        );
        return;
      }
    }
    if (overUnderFilter?.minValue != null && overUnderFilter.maxValue != null && overUnderFilter?.minValue != "" && overUnderFilter.maxValue != "") {
      if (payload.variance_min > payload.variance_max) {
        dispatch(
          slice.actions.updateToast({
            severity: "error",
            message: "Leak Check Status minimum value is not to be greater than the maximum value ",
          })
        );
        return;
      }
    }
    commonService.startLoading();
    RolesService.getFuelSystemLinesDetailsParentList(lazyState.page + 1, lazyState.rows, payload, sortType, sortField)
      .pipe(errorHandler("Get Fuel System Lines Details Parent List"))
      .subscribe((response) => {
        if (response?.data) {
          const data = (response?.data);
          setFuelMapping({ fuelList: data.results, totalRecords: data.count });
        }
        commonService.stopLoading();
        setChartData(null)
      });
  };

  const handleInputChange = (e) => {
    setFormValues((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const handleLeakCheckInputChange = (e) => {
    setOverUnderFilter((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const containerRef = useRef(null);
  const dataTableWrapperRef = useRef(null);
  const startY = useRef(0);
  const startHeight = useRef(0);

  const onMouseDown = (e) => {
    startY.current = e.clientY;
    startHeight.current = containerRef.current.offsetHeight;
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const onMouseMove = (e) => {
    const newHeight = startHeight.current + (e.clientY - startY.current);
    containerRef.current.style.height = `${newHeight}px`;

    if (dataTableWrapperRef.current) {
      dataTableWrapperRef.current.style.height = `${newHeight - 40}px`; // Adjust to fit content
    }
  };

  const onMouseUp = () => {
    document.removeEventListener("mousemove", onMouseMove);
    document.removeEventListener("mouseup", onMouseUp);
  };
  useEffect(() => {
    dataTableWrapperRef.current = document.querySelector("#inventoryTruckGrid .p-datatable-wrapper");
    if (gridHeightMode === "Component" && containerRef.current) {
      const container = containerRef.current;
      container.style.height = "calc(100vh - 10px)";
      if (dataTableWrapperRef.current) {
        const containerHeight = container.offsetHeight;
        dataTableWrapperRef.current.style.height = `${containerHeight - 40}px`;
      }
    }
  }, [gridHeightMode]);

  const rowExpansionTemplate = (data) => {
    return (
      <div >
        <Dialog appendTo="self" visible={!!formData} align={"left"} modal header={"Notes"} onHide={() => setFormData(null)}>
          <NoteDialog sites={sites} editData={formData} onCancel={() => setFormData(null)} name="fuelline-details" />
        </Dialog>
        <div ref={containerRef} className={tableId ? "h-30 rounded overflow-auto" : "border rounded overflow-auto h-10 grid-resizable-inventory-container"} style={{ minHeight: "200px" }}>
          <DataTable
            value={childData.fuelList || []}
            // id="fuelLinesChildGrid"
            id={tableId ?? "inventoryTruckGrid fuelLinesChildGrid"}
            dataKey={childData?.id}
            showSelectAll={false}
            editMode="row"
            resizableColumns columnResizeMode={"expand"} reorderableColumns
            selection={selectedProducts[data.id]}
            onRowEditComplete={onReadingEditComplete}
            onRowEditCancel={onReadingEditCancel}
            onRowEditInit={onRowEditInit}
            scrollable={true}
            scrollHeight={"462px"}
            onSelectionChange={(e) =>
              setSelectedProducts({ ...selectedProducts, [data.id]: e.value })
            }
            className="child-fuel-grid rounded border flex-grow-1"
          >
            <Column field="tank_number" header="Tank Number" headerStyle={{ width: "7rem" }} />
            <Column field="product" header="Product" />
            <Column field="only_date" header="Date" body={(rowData) => formatDate(rowData?.only_date)} />
            <Column
              field="beginning_inventory_volume"
              header="Beginning Inventory Volume"
              headerStyle={{ width: "4rem" }}
              editor={(props) => (
                <InputNumber
                  value={props.rowData?.beginning_inventory_volume || 0}
                  onChange={(e) =>
                    handleValueChange(e, "beginning_inventory_volume", props.rowIndex, childData.fuelList, data.site_id)
                  }
                />
              )}
            />
            <Column
              field="actual_ending_inventory_volume"
              header="Actual Ending Inventory Volume"
              headerStyle={{ width: "4rem" }}
              editor={(props) => (
                <InputNumber
                  value={props.rowData?.actual_ending_inventory_volume || 0}
                  onChange={(e) =>
                    handleValueChange(e, "actual_ending_inventory_volume", props.rowIndex, childData.fuelList, data.site_id)
                  }
                />
              )}
            />
            <Column
              field="sales"
              header="Sales"
              editor={(props) => (
                <InputNumber
                  value={props.rowData?.sales || 0}
                  onChange={(e) =>
                    handleValueChange(e, "sales", props.rowIndex, childData.fuelList, data.site_id)
                  }
                />
              )}
            />
            <Column
              field="deliveries"
              header="Deliveries"
              editor={((props) => (
                <InputNumber
                  value={props.rowData?.deliveries || 0}
                  onChange={(e) =>
                    handleValueChange(e, "deliveries", props.rowIndex, childData.fuelList, data.site_id)
                  }
                />
              ))}
            />
            <Column field="book_value" body={(rowData) => formatNumber(rowData?.book_value)} header="Book Value" />
            <Column field="variance" body={(rowData) => formatNumber(rowData?.variance)} header="Variance" />
            <Column field="cumulative_variance" body={(rowData) => formatNumber(rowData?.cumulative_variance)} header="Cumulative Variance" />
            <Column field="daily_variance" body={(rowData) => formatNumber(rowData?.daily_variance)} header="Daily Variance" />
            <Column field="daily_variance_status" header="Daily Variance Status" />
            <Column header={"Notes"} align={'center'} body={NoteCustomButton} />
            <Column rowEditor header={"Actions"} align={"center"} body={actionColumn} />
          </DataTable>
          {gridHeightMode === "Tab" && <div className="resizable-handle" onMouseDown={onMouseDown} />}
        </div>
      </div>
    );
  };
  useEffect(() => {
    const matchingProduct = fuelMapping?.fuelList.find(
      (data) => data.Fule_System_Name === chartData?.product
    );
    const index = fuelMapping?.fuelList.findIndex(
      (data) => data?.Fule_System_Name === chartData?.product
    );
    if (matchingProduct) {
      const toggleButtons = document.querySelectorAll('.p-row-toggler');
      if (toggleButtons[index]) {
        const toggleButton = toggleButtons[index];
        if (toggleButton.getAttribute('aria-expanded') === 'false') {
          toggleButton.click();
        }
      }
    }
  }, [fuelMapping.fuelList, chartData?.product]);

  const onRowToggle = (e) => {
    setChildData({ fuelList: [] });

    if (e.originalEvent?.originalEvent?.currentTarget?.ariaExpanded === "false") {
      childDataCall(e.data);
      setExpandedParentData(e.data);
    } else {
      setExpandedParentData({});
    }
    if (expandedRow && expandedRow.id === e.data.id) {
      setExpandedRow(null);
    } else {
      setExpandedRow(e.data);
    }
  };
  const allowExpansion = (rowData) => {
    return true;
  };

  const onShow = () => {
    let selectAllCheckbox = document.querySelector(
      ".p-multiselect-header > .p-multiselect-select-all > .p-checkbox"
    );
    selectAllCheckbox.after(" Select All");
  };

  const handlePageChange = (e) => {
    setLazyState(e);
  };
  const handleRowsPerPageChange = (e) => {
    setLazyState({
      ...lazyState,
      rows: e.value,
      page: 0,
      first: 0
    });
  };
  const handleOnSortChange = (e) => {
    let id = `custom-sort-icon-${e.sortField}`
    const svgElement = document.getElementById(id);
    let elements = document.querySelectorAll('.custom-sort-icon');
    elements?.forEach(element => element.classList?.remove('sort-asc', 'sort-desc'))
    setSortField(e.sortField);
    setSortOrder(e.sortOrder)
    let sortValue;
    if (e.sortOrder === 1) {
      sortValue = "desc"
      svgElement.classList.add('sort-asc');
      svgElement.classList.remove('sort-desc');
    } else if (e.sortOrder === -1) {
      sortValue = "asc"
      svgElement.classList.add('sort-desc');
      svgElement.classList.remove('sort-asc');
    } else {
      sortValue = "asc"
      svgElement.classList.add('sort-desc');
      svgElement.classList.remove('sort-asc');
    }
    setSortType(sortValue)
  }

  return (

    <div className="d-flex flex-column p-0 pt-0 fsl-main-container">

      <div className="col-12 d-flex flex-row flex-wrap flex-grow-1 p-0 pt-2 mb-0">
        <div className="col-12 col-md-1 d-flex flex-column align-items-center gap-1 px-1 flex-grow-1  mx-0 mt-0 p-2">
          <div className="d-flex flex-column align-items-start w-100">
            <label htmlFor="site">Site Name</label>
            <MultiSelect
              value={filters?.sites}
              options={sites}
              loading={!sites?.length}
              optionLabel="facility_name"
              onChange={(e) => { setGlobalFilters('sites', e.value); }}
              placeholder="Select Sites"
             // onShow={onShow}
              filter
              maxSelectedLabels={3}
              display='chip'
              // selectAllLabel="Select All"
              className="w-100"
            />
          </div>
        </div>
        <div className="col-12 col-md-1 d-flex flex-column align-items-center gap-1 px-1 flex-grow-1  mx-0 mt-0 p-2">
          <div className="d-flex flex-column align-items-start w-100">
            <label htmlFor="state">State</label>
            <MultiSelect
              value={filters?.statesValue}
              options={stateOptions}
              loading={!stateOptions?.length}
              optionValue="name"
              optionLabel="name"
              onChange={(e) => setGlobalFilters('statesValue', e.value)}
              placeholder="Select States"
              maxSelectedLabels={3}
             // onShow={onShow}
              display='chip'
             // selectAllLabel="Select All"
              filter
              className="w-100"
            />
          </div>
        </div>
        <div className="col-12 col-md-1 d-flex flex-column align-items-center gap-1 px-1 flex-grow-1  mx-0 mt-0 p-2">
          <div className="d-flex flex-column align-items-start w-100">
            <Tooltip target=".custom-target-icon" />
            <label htmlFor="date">Date Range <i className="custom-target-icon pi pi-info-circle p-text-secondary"
              data-pr-tooltip="Default date range is T-30 days"
              data-pr-position="right"
              style={{ fontSize: '0.8rem', cursor: 'pointer' }}>
            </i></label>
            <Calendar
              value={filters.dates}
              onShow={OnShowCalendar}
              onChange={({ target: { name, value } }) => { setGlobalFilters(name, value); OnShowCalendar() }}
              onHide={() => (!filters.dates?.[0] || !filters.dates?.[1]) && setGlobalFilters("dates", null)}
              selectionMode="range"
              readOnlyInput
              hideOnRangeSelection
              placeholder="Select Date"
              id="dates"
              name="dates"
              showButtonBar
              // numberOfMonths={2}
              className="w-100"
            />
          </div>
        </div>

        <fieldset className="col-12 col-md-3 d-flex flex-column gap-1  flex-grow-1  mt-0 fsld-fieldset">
          <legend className="fsld-legend">Leak Check Status</legend>
          <div className="d-flex flex-row">
            <Dropdown
              value={statusValue}
              onChange={(e) => setStatuesValue(e?.value)}
              options={statusList}
              optionLabel="name"
              placeholder="Status"
              optionValue="name"
              id="status"
              name="status"
              style={{ width: "50%", margin: '10px' }}
            />
            <div className="d-flex align-items-center field-wrapper">
              <label className="p-2">{greatervalue}</label>
              <InputText type="number" value={overUnderFilter?.minValue}
                onChange={(e) => handleLeakCheckInputChange(e)} name="minValue"
                style={{ width: "50%", margin: '10px' }} />
            </div>
            <div className="d-flex align-items-center field-wrapper">
              <label className="p-2">{LessThanvalue}</label>
              <InputText type="number" min={overUnderFilter?.minValue} value={overUnderFilter?.maxValue}
                onChange={(e) => handleLeakCheckInputChange(e)} name="maxValue"
                className="field-wrapper" style={{ width: "50%", margin: '10px' }} />
            </div>
          </div>
        </fieldset>
        <fieldset className="col-12 col-md-3 d-flex flex-column gap-1  flex-grow-1  mt-0 fsld-fieldset fsl-filedsetleg">
          <legend className="fsld-legend">Daily Variance Status</legend>
          <div className="d-flex flex-row ">
            <Dropdown
              value={dailyVarianceStatus}
              onChange={(e) => setDailyVarianceStatus(e?.value)}
              options={statusList}
              optionLabel="name"
              placeholder="Status"
              optionValue="name"
              id="status"
              name="maxValue"
              // className="p-dropdown flex-grow-1"
              style={{ width: "50%", margin: '10px' }}
            />
            <div className="d-flex align-items-center field-wrapper">
              <label className="p-2">{greatervalue}</label>
              <InputText type="number" value={formaValues?.minValue}
                onChange={(e) => handleInputChange(e, "")} name="minValue"
                style={{ width: "50%", margin: '10px' }} />
            </div>
            <div className="d-flex align-items-center field-wrapper">
              <label className="p-2">{LessThanvalue}</label>
              <InputText type="number" min={formaValues?.minValue} value={formaValues?.maxValue}
                onChange={(e) => handleInputChange(e, "")} name="maxValue"
                className="field-wrapper" style={{ width: "50%", margin: '10px' }} />
            </div>
          </div>
        </fieldset>
      </div>
      <div className="col-12 d-flex flex-row flex-wrap flex-grow-1 p-0 pt-2 mb-0">
        <div className="col-12 col-md-2 d-flex flex-row justify-content-end align-items-end  gap-1 p-0 px-1 flex-grow-1 m-2 mx-0 mt-0">
          <Button label="Apply" className="filter-sign" onClick={onFilter} text>
            <i className="pi pi-filter filter-action-icon"></i>
          </Button>
          <Button label="Export" className="filter-sign" text onClick={(e) => { exportSitesData(e, filters, dailyVarianceStatus, formaValues) }}>
            <i className="pi pi-upload export-action-icon"></i>
          </Button>
          <div className="d-flex gap-1">
            <span className="mt-1 mx-1">Show: </span>
            <Dropdown
              value={lazyState.rows}
              options={rowsPerPageOptions}
              onChange={handleRowsPerPageChange}
              className="p-dropdown flex-grow-1"
            />
          </div>
        </div>
      </div>

      <div>
        <DataTable
          id="fuel-lines-grid"
          ref={dt}
          dataKey="id"
          showGridlines={false}
          scrollable
          value={fuelMapping?.fuelList}
          size="large"
          className="fuel-system-grid rounded border flex-grow-1"
          paginator
          totalRecords={fuelMapping?.totalRecords}
          pageLinkSize={10}
          rowsPerPageOptions={rowsPerPageOptions}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
          paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
          reorderableColumns
          resizableColumns
          lazy={true}
          rows={lazyState.rows}
          first={lazyState.first}
          rowExpansionTemplate={rowExpansionTemplate}
          expandedRows={[expandedRow]}
          onRowExpand={onRowToggle}
          onRowCollapse={onRowToggle}
          emptyMessage="No records found."
          sortField={sortField}
          onSort={handleOnSortChange}
          sortOrder={sortOrder}
          sortIcon={(e) => { return <SortIcon id={`custom-sort-icon-${e?.props?.column?.props?.field}`} className='custom-sort-icon' /> }}
          onPage={handlePageChange}>
          <Column expander={allowExpansion} style={{ width: "3rem" }} />
          <Column field="client_name" header="Client Name" className="text-nowrap" />
          <Column field="facility_name" sortable header="Site Name" />
          <Column field="state" header="State" />
          <Column field="Fule_System_Name" header="Fuel System Name" />
          <Column field="Period_Start" body={(rowData) => formatDate(rowData?.Period_Start)} header="Period Start" />
          <Column field="Period_End" body={(rowData) => formatDate(rowData?.Period_End)} header="Period End" />
          <Column field="Last_Reading" body={(rowData) => formatDate(rowData?.Last_Reading)} header="Last Reading" />
          <Column field="Next_Reading" body={(rowData) => formatDate(rowData?.Next_Reading)} header="Next Reading" />
          <Column field="Periodic_Sales" body={(rowData) => formatNumber(rowData?.Periodic_Sales)} header="Periodic Sales" />
          <Column field="Periodic_Deliveries" body={(rowData) => formatNumber(rowData?.Periodic_Deliveries)} header="Periodic Deliveries" />
          <Column field="Over_Under" sortable body={(rowData) => formatNumber(rowData?.Over_Under)} header="Over/Under" />
          <Column field="result" sortable header="Leak Check Status" />
        </DataTable>
      </div>
    </div>
  );
}

export default FuelSystemLinesDetailTab;