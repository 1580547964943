import * as CryptoJS from "crypto-js";
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
const key = process.env.REACT_APP_DECRYPT_KEY;

export const DecryptHelper = {
    decryptWithKey: (data) => {
        try {
            let decrypted = fndecryptData(data);
            console.log(decrypted);
            return decrypted;
        } catch (e) {
            console.log(e);
            return e;
        }
    },
};

function fndecryptData(ciphertextB64) {
    // Base64 encoded ciphertext, 32 bytes string as key
    let decrypted = CryptoAES.decrypt(ciphertextB64.toString(), key)
    return decrypted.toString(CryptoENC); // Convert into string (using Utf8)
}
