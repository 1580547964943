import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    profile: null,
    loggedIn: localStorage.getItem("token") || false,
    isRefreshToken:null,
    toast:{
        severity:null,
        message:null
    }
};

const slice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logIn(state, action) {
            const { token, refreshToken, loggedIn,accessTokenExpiry,isRefreshToken,role } = action.payload;
            localStorage.setItem("token", token);
            localStorage.setItem("refresh_token", refreshToken); 
            localStorage.setItem("access_token_expiry", accessTokenExpiry);
            localStorage.setItem("Role", role);
            state.isRefreshToken = isRefreshToken
            state.loggedIn = loggedIn;
        },
        updatename(state,action){
            state.organization=action?.payload?.organization
           },
        logout(state) {
            state.loggedIn = false;
            state.profile = null;
            state.isRefreshToken=false
            localStorage.removeItem("token");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("access_token_expiry");
            localStorage.removeItem("profile");
            localStorage.removeItem("Role");
            window.location.reload("/login")
        },
        profile(state, action) {
            localStorage.setItem("profile", action.payload.profile);
            state.profile = action.payload.profile;
        },
        updateToast(state, action){
            state.toast = action.payload
        }
    },
});

export function UpdateToast({severity, message}) {
    return (dispatch, getState) => {
            dispatch(slice.actions.updateToast({
                severity:severity,
                message:message
            }))
    };
}

export { slice }
export default slice.reducer;

