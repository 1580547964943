import { Navigate, Outlet, useLocation } from "react-router-dom";
import { DecryptHelper } from "../services/decrypt";

const ProtectedRoute = (props) => {
    let auth = localStorage.getItem("token");
    let location = useLocation();
    if (location.search.indexOf('?Session') > -1) {
        let resp = DecryptHelper.decryptWithKey(location.search.replace("?Session=", "").replaceAll("p1L2u3S", "+"));
        localStorage.setItem('Session', resp);
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('access_token_expiry');
        localStorage.removeItem('Role');
        auth = null;
    }
    return (
        auth ? <Outlet /> : <Navigate to={"/login"} />
    )
}

export default ProtectedRoute