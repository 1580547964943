import { lastValueFrom, Observable } from "rxjs";
import { ApiService } from "./api.service.ts";
import { LOGIN_URLS } from "./apiUrls.ts";

interface LoginBody {
  email: string;
  password: string;
}

export const AuthService = {
  login: (body: LoginBody) => {
    return new Observable<any>((observer) => {
      ApiService.call(LOGIN_URLS.LOGIN, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  getUser: (): Observable<any> => {
    return new Observable<any>((observer) => {
      const url = LOGIN_URLS.PROFILE;
      ApiService.call(url, "GET", "").subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          if (error?.response?.data?.error == "Token has expired" || error?.response?.data?.error == "Invalid token") {
            localStorage.removeItem("token")
            localStorage.removeItem("profile")
            window.location.href = "/login"
          }
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  register: (body: any) => {
    return new Observable<any>((observer) => {
      ApiService.call(LOGIN_URLS.REGISTER, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  getRefreshToken: (body: any) => {
    const _body = JSON.stringify(body)
    return new Observable<any>((observer) => {
      ApiService.call(LOGIN_URLS.REFRESH_TOKEN, "POST", _body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      })
    })
  },
  getbyPassUser: (body: any) => {
    return lastValueFrom(ApiService.call(LOGIN_URLS.SSOBYPASS, "POST", body))
  },

}